<template>
    <div id="chart">
        <apexchart type="rangeBar" :height="height" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    //computed
    function chartOptions(){
        let options = {
            chart: {
                height: 'auto',
                type: 'rangeBar'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '50%',
                    rangeBarGroupRows: true
                }
            },
            fill: {
                type: 'solid'
            },
            xaxis: {
                type: 'datetime'
            },
            legend: {
                position: 'right'
            },
        }
        if(this.colors.length !== 0){
            options.colors = this.colors;
        }
        return options;
    }
    export default {
        name:'multipleSeriesTimeLine',
        props:{
            height:{
                type: String,
                required: true,
                default: '430',
            },
            colors: {
                type: Array,
                required: false,
                default: () => [],
            },
            series: {
                type: Array,
                required: true,
                default: () => [],
            },
        },
        computed:{
            chartOptions
        }
    }
</script>

<style scoped>
    #chart {
        width:100%;
        max-width: 100%;
    }
</style>